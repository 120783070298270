<!--
This view displays which tags are the most popular.
a prop name maps comes as is:
"maps": [
          {
            "islandCode": "1760-0152-1306",
            "name": "TILTED ZONE WARS (ALL WEAPONS) ⭐",
            "author": "PrettyBoyyAM",
            "image": "https://cdn-0001.qstv.on.epicgames.com/HCEqtcwuoiAFNdODTG/image/screen_comp.jpeg",
            "playerCount": 5576,
            "tags": ["deathrun","parkour","just for fun","difficulty: easy"]
        },
        ...
]
3) for each tag accumulate the number of players on maps with that tag.
It displays a bar chart of the number of players on each tag is descending order.
It displays a second bar chart of the number of maps with each tag in descending order.
It displays a third bar chart with the ratio of number of player per map for each tag in the same order.
It uses vanilla Chart.js 2.9.4 library to display the graph, no vue wrapper.
-->
<template>
    <div>
        <h2>Popular {{ field }}</h2>
        <div class="scrollable-x">
            <div class="chartWrapper" :style="`width:${20*playerCounts.length}px`" >
                <canvas ref="chart" height="12" width="0"></canvas>
            </div>
        </div>
        <h2>Popular {{ field }} by Map Count</h2>
        <div class="scrollable-x">
            <div class="chartWrapper" :style="`width:${20*mapCounts.length}px`">
                <canvas ref="chart2" height="12" width="0"></canvas>
            </div>
        </div>
        <h2>Popular {{ field }} by Player per Map Ratio</h2>
        <div class="scrollable-x">
            <div class="chartWrapper" :style="`width:${20*playerPerMapRatios.length}px`">
                <canvas ref="chart3" height="12" width="0"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js';
import { getLog } from '@/services/log';

const log = getLog('popular-tags');

export default {
    name: 'PopularTags',
    props: {
        maps: {
            type: Array,
            required: true
        },
        field: {
            type: String,
            default: 'tags'
        }
    },
    data() {
        return {
            snapshotDate: null,
            tags: [],
            playerCounts: [],
            mapCounts: [],
            playerPerMapRatios: [],
            chart: null,
            chart2: null,
            chart3: null,
            backgroundColors: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColors: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
        };
    },
    watch: {
        maps() {
            this.updateChart();
        }
    },
    mounted() {
        this.updateChart();
    },
    methods: {
        updateChart() {
            try {
                const allTags = this.maps.reduce((acc, map) => [...acc, ...map[this.field]], []);
                const uniqueTags = [...new Set(allTags)];
                const tagPlayerCounts = uniqueTags.map((tag) => {
                    const playerCount = this.maps.filter((map) => map[this.field].includes(tag)).reduce((acc, map) => acc + map.playerCount, 0);
                    return playerCount;
                });
                const tagMapCounts = uniqueTags.map((tag) => {
                    const mapCount = this.maps.filter((map) => map[this.field].includes(tag)).length;
                    return mapCount;
                });
                const tagsAndCounts = uniqueTags.map((tag, index) => {
                    return { tag: tag, count: tagPlayerCounts[index], mapCount: tagMapCounts[index] };
                });
                tagsAndCounts.sort((a, b) => b.count - a.count);
                this.tags = tagsAndCounts.map((tagAndCount) => tagAndCount.tag);
                this.playerCounts = tagsAndCounts.map((tagAndCount) => tagAndCount.count);
                this.mapCounts = tagsAndCounts.map((tagAndCount) => tagAndCount.mapCount);
                this.playerPerMapRatios = tagsAndCounts.map((tagAndCount) => tagAndCount.count / tagAndCount.mapCount);
                this.renderChart();
            } catch (error) {
                log.error(error);
            }
        },
        getColors(length) {
            return {
                backgroundColor: Array(length).fill().map((_, i) => this.backgroundColors[i % this.backgroundColors.length]),
                borderColor: Array(length).fill().map((_, i) => this.borderColors[i % this.borderColors.length]),
            };
        },
        renderChart() {
            this.chart?.destroy();
            this.chart2?.destroy();
            this.chart3?.destroy();
            const ctx = this.$refs.chart.getContext('2d');
            const ctx2 = this.$refs.chart2.getContext('2d');
            const ctx3 = this.$refs.chart3.getContext('2d');
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.tags,
                    datasets: [
                        {
                            label: 'Number of Players',
                            data: this.playerCounts,
                            ...this.getColors(this.playerCounts.length),
                            borderWidth: 1,
                        },
                    ],
                },
            });
            this.chart2 = new Chart(ctx2, {
                type: 'bar',
                data: {
                    labels: this.tags,
                    datasets: [
                        {
                            label: 'Number of Maps',
                            data: this.mapCounts,
                            ...this.getColors(this.mapCounts.length),
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            }); // end of chart2 initialization

            this.chart3 = new Chart(ctx3, {
                type: 'bar',
                data: {
                    labels: this.tags,
                    datasets: [
                        {
                            label: 'Player per Map Ratio',
                            data: this.playerPerMapRatios,
                            ...this.getColors(this.playerPerMapRatios.length),
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            }); // end of chart3 initialization
        },
    }
};
</script>

<style scoped>
.scrollable-x {
    overflow: auto;
    overflow-x: scroll;
    width: calc(100vw);
}

.chartWrapper {
    height: 400px;
}
</style>
